.zipcode {
  display: flex;
  border: 1px solid #e2e9e5;
  border-radius: 50px;
  padding: 2px;
  justify-content: space-between;
  background-color: #fff;

  input {
    padding: 16px 0 16px 16px;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    width: 50%;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #88928c;
    }
  }
  .button_desktop {
    padding: 18px 24px;
    background-color: #ffd40f;
    color: #164230;
    font-weight: bold;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 960px) {
  .zipcode {
    width: 100%;
    .button_desktop {
      padding: 18px 24px;
      align-items: center;
    }
    .button_mobile {
      display: flex;
      padding: 14px;
      background-color: #ffd40f;
      border-radius: 50%;
    }
  }
}

.error_zipcode {
  color: red;
  margin: 0;
  max-width: 400px;
  .signup_zipcode {
    text-decoration: underline;
  }
}
