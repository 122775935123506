.share_button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
  gap: 8px;
  border: 1px solid #e2e9e5;
  border-radius: 50px;
  color: #f06e36;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}
