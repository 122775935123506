.promotion_banner {
  padding: 16px;
  background-color: #ffd183;
  text-align: center;
  p {
    margin: 0;
    display: inline;
  }
  a {
    text-decoration: underline;
    font-weight: 500;
    display: inline;
    margin-left: 6px;
  }
}

@media (max-width: 960px) {
  .promotion_banner {
    text-align: left;
  }
}
