.articles_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  h1 {
    display: flex;
    justify-content: center;
    margin: 0 0 80px 0;
    width: 100%;
    font-weight: 400;
    font-family: "Bebas Neue";
    color: #007044;
    font-size: 96px;
    padding: 80px 0;
    background-color: #ffedcf;
  }

  .articles {
    display: flex;
    width: 100%;
  }
  .articles_section {
    max-width: 1120px;
    padding: 0 40px 0 120px;

    .article {
      display: flex;
      margin-bottom: 80px;

      img {
        min-width: 343px;
        height: 228px;
        margin-right: 40px;
      }

      .article_info_container {
        ul {
          display: flex;
          margin: 0 0 4px 0;
          padding: 0;
          gap: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #4c5450;

          li {
            list-style: none;
            position: relative;

            &:nth-child(n + 2)::before {
              content: "";
              position: absolute;
              left: -11px;
              top: 11px;
              height: 3px;
              width: 3px;
              border-radius: 50%;
              background-color: #4c5450;
            }
          }
        }

        .article_title {
          margin: 0 0 12px 0;
          font-size: 28px;
          font-weight: 600;
          color: #164230;
        }

        .article_info {
          display: flex;
          gap: 19px;
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #4c5450;

          .comments {
            display: flex;
            align-items: center;
            column-gap: 6px;
          }
        }

        p {
          max-width: 500px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .tags_container {
    min-height: 100vh;
    width: 560px;
    padding-left: 40px;
    border-left: 1px solid #d1d9d4;

    h3 {
      margin: 0 0 24px 0;
      font-size: 32px;
      font-weight: 600;
      color: #007044;
      font-family: "Bebas Neue";
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      gap: 12px;
      font-weight: 400;
      font-size: 18px;

      li {
        border-radius: 4px;
        width: fit-content;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .articles_container {
    h1 {
      text-align: center;
      font-size: 56px;
    }
    .articles {
      flex-direction: column;
      .articles_section {
        order: 1;
        padding: 0 16px;
        .article {
          column-gap: 16px;
          margin-bottom: 46px;
          justify-content: space-between;
          img {
            min-width: 140px;
            width: 140px;
            height: 140px;
            margin-right: 0;
            object-fit: cover;
            order: 1;
          }
          .article_info_container {
            .article_title {
              font-size: 24px;
              line-height: 24px;
            }
            p {
              display: none;
            }
            ul {
              li {
                display: none;
              }
            }
            .article_info {
              display: flex;
              flex-wrap: wrap;
              column-gap: 24px;
              row-gap: 0;
              .comments {
                display: none;
              }
            }
          }
        }
      }

      .tags_container {
        min-height: fit-content;
        width: fit-content;
        padding: 0 0 0 16px;
        margin-bottom: 60px;
        width: 100%;

        h3 {
          margin-bottom: 16px;
        }

        ul {
          flex-direction: row;
          width: 100%;
          overflow-x: auto;
          white-space: nowrap;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
