.single_recipe {
  padding: 40px 100px;
  margin-bottom: 80px;
  .navigation_button {
    display: flex;
    align-items: center;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }
  .tags {
    list-style: none;
    display: flex;
  }

  h1 {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    color: #007044;
    margin: 0 0 20px 0;
  }
  .recipe_info {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 32px;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      color: #4c5450;
    }
  }

  .socials_container {
    display: flex;
    column-gap: 16px;
    margin-bottom: 80px;
  }

  .ingredients_container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    img {
      width: 100%;
      height: 408px;
      object-fit: cover;
      margin-bottom: 80px;
    }

    .ingredients_content {
      padding-right: 134px;
    }
  }

  h2 {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    color: #007044;
    margin: 0;
  }

  .ingredients {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 32px;
    margin-bottom: 80px;

    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e2e9e5;
      padding: 16px 0;
      column-gap: 12px;

      p {
        margin: 0;
      }

      span {
        margin-left: auto;
        font-weight: 700;
        font-size: 18px;
        color: #164230;
      }
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    .steps_header {
      display: flex;
      align-items: center;
      column-gap: 16px;
      h5 {
        font-family: "Bebas Neue";
        font-weight: 400;
        font-size: 32px;
        color: #164230;
        margin: 0;
      }
    }
    li {
      padding-left: 60px;
      font-weight: 400;
      font-size: 18px;
      color: #164230;
    }
  }
}

@media (max-width: 960px) {
  .single_recipe {
    padding: 0;
    margin-bottom: 0;

    .article_header {
      padding: 16px;
    }
    .ingredients_container {
      flex-direction: column;

      .ingredients_content {
        padding: 16px;

        .ingredients {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
