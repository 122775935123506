.latest_article_container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  div {
    margin-bottom: 32px;
    h2 {
      font-family: "Bebas Neue";
      font-weight: 400;
      font-size: 56px;
      line-height: 100%;
      color: #164230;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #164230;
    }

    .latest_article_button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 24px;
      gap: 8px;
      width: fit-content;
      height: 56px;
      font-weight: 500;
      color: #164230;
      background: #ffd40f;
      border-radius: 50px;
    }
  }
}

@media (min-width: 960px) {
  .latest_article_container {
    column-gap: 40px;
    width: 100%;
    overflow: initial;
    .latest_article_description {
    }
  }
}
