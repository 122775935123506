.zipcode_container {
  padding: 50px 22px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 37px;
    max-width: 300px;
    color: #164329;
    text-align: center;
    margin: 0 0 17.5px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 510;
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  input[type="text"] {
    margin-bottom: 10px;
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    background-color: #f6f6f6;
    color: #164329;
    line-height: 1.2;
    border-radius: 2px;
    padding: 8px 15px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .submit_button {
    appearance: none;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    max-width: 400px;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 8px 15px;
    background-color: #fc7033;
    color: #3c1504;
    font-family: Poppins, sans-serif;
    font-style: normal;
    width: 100%;
    font-weight: 510;
    border-radius: 4px;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 13px;
  }
}
