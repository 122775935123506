.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  padding: 0 150px;

  .composition_description {
    color: #757575;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 20px;
    align-self: baseline;
  }
  
  .basket_faq {
    width: 100%;
    padding: 32px 0 64px 0;
  }

  h1 {
    color: #2e624f;
  }
}
.basket_container {
  width: 100%;
}

.basket {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  column-gap: 50px;

  img {
    max-width: 45%;
    min-width: 30%;
    height: 100%;
    flex: 1;
  }

  .basket_info {
    display: flex;
    flex-direction: column;

    .basket_weights {
      .product-alternate__variant-title {
        margin: 10px 0 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.15px;
        color: #000000de;
      }

      .product-alternate__variant-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.15px;
        color: #000000de;
      }
    }

    p {
      margin: 0;
      margin-bottom: 20px;
    }

    .basket_info_switch {
      display: flex;
      width: 100%;
      background: rgba(165, 165, 182, 0.12);
      border-radius: 8px;
      padding: 3px;
      margin-bottom: 20px;

      span {
        width: 100%;
        border: none;
        text-align: center;
        border-radius: 6px;
        padding: 5px 7px;
        cursor: pointer;
        font-size: 13px;
      }
    }

    .basket_composition_container {
      display: flex;
      column-gap: 20px;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 20px;

      span {
        background: rgba(165, 165, 182, 0.12);
        cursor: pointer;
        border-radius: 16px;
        border: none;
        padding: 3px 8px;
        font-size: 13px;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
  }
}

.order_section {
  display: flex;
  justify-content: space-between;

  .order_section_basket_price {
    .price {
      display: flex;
      font-weight: 600;
      column-gap: 10px;
    }
    p {
      margin: 0;
      color: #164329;
      &:first-child {
        text-decoration: line-through;
        color: #757575;
      }
    }
  }

  .order-button {
    border: none;
    display: flex;
    width: 280px;
    align-items: center;
    justify-content: center;
    background-color: #fc7033;
    color: #3c1504;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 8px;
  }
}

@media (max-width: 960px) {
  .container {
    padding: 0 20px 100px 20px;

    h1 {
      text-align: center;
      margin-bottom: 0;
      color: #2e624f;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 70px;
    }

    .composition_description {
      color: #757575;
      font-size: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  .basket {
    position: relative;
    flex-direction: column;

    img {
      max-width: 100%;
      min-width: 100%;
      aspect-ratio: 10/7;
      height: initial;
      flex: initial;
    }

    .basket_info {
      .basket_info_switch {
        position: absolute;
        top: -6%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .order_section {
        position: absolute;
        bottom: 0;
        background: #fafafa;
        box-shadow: 0 3px 11px #0003;
        position: fixed;
        left: 0;
        right: 0;
        padding: 6px 20px;
        bottom: 0;
        z-index: 12;

        .order-button {
          border: none;
          width: auto;
          background-color: #fc7033;
          color: #3c1504;
          font-family: Poppins, sans-serif;
          font-weight: 600;
          font-size: 16px;
          padding: 10px 20px;
          border-radius: 8px;
          text-transform: unset;
          letter-spacing: unset;
        }
      }
      .basket_composition_container {
        order: -1;
        margin-bottom: 0;
        gap: 0;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
}
