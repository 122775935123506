.composition_heading {
  color: #2e624f;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 30px;
  font-weight: 600;
}

.composition_container {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  box-sizing: content-box;
  column-gap: 10px;
  margin-bottom: 20px;

  .composition_items {
    max-width: 250px;
    img {
      width: 250px;
      height: 118px;
      object-fit: cover;
      border-radius: 8px;
    }

    .composition_name {
      color: #339760;
      width: 100%;
      margin: 5px 0 0;
      font-size: 14px;
      overflow span {
        color: #757575;
      }
    }

    .composition_reason {
      font-size: 13px;
      margin: 0px;
    }

    .compositon_producer {
      display: flex;
      flex-direction: column;
      color: #757575;
      font-size: 13px;

      p {
        margin: 0;
      }
    }
  }
}

@media (min-width: 960px) {
  .composition_heading {
    align-self: baseline;
  }
}
