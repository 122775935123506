.price_slider_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 100px;
  column-gap: 120px;
  min-width: 100%;

  .slider_title {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 56px;
    color: #164230;
  }

  h4 {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 24px;
    color: #164230;
  }

  .price_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;

    .price {
      font-family: "Bebas Neue";
      font-weight: 400;
      font-size: 96px;
      color: #f06e36;
      margin: 0;
    }

    .price_description {
      font-weight: 400;
      font-size: 18px;
      color: #164230;
      text-align: center;
      margin: 0;
      margin-bottom: 24px;

      span {
        color: #f06e36;
        font-weight: 600;
      }
    }

    .price_button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 24px;
      background: #ffd40f;
      border-radius: 50px;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #164230;
    }

    .price_promo {
      font-weight: 400;
      font-size: 18px;
      color: #164230;
      margin: 0;
    }
  }
}
#tickmarks_frequency,
#tickmarks_economy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

input[type="range"] {
  appearance: none;
  min-width: 600px;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#f06e36, #f06e36);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  border-radius: 50px;
  height: 12px;
  transition: background-size 0.4s ease-in-out;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    transform: translateY(-5px);
    width: 32px;
    height: 32px;
    background: #f06e36;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(252, 112, 51, 0.25);
  }
  &::-moz-range-thumb {
    appearance: none;
    width: 28px;
    height: 28px;
    background: #f06e36;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(252, 112, 51, 0.25);
  }
}

@media (max-width: 960px) {
  .price_slider_container {
    flex-direction: column;
    padding: 60px 16px;

    .slider_title {
      max-width: 350px;
      font-size: 56px;
    }

    input[type="range"] {
      min-width: 350px;
      max-width: 350px;
    }

    datalist {
      min-width: 350px;
      max-width: 350px;
    }

    .price_container {
      margin-top: 60px;
    }
  }
}
