.navbar {
  background-color: #ffedcf;
  display: flex;
  align-items: center;
  padding: 8px 100px;
  top: 0;
  z-index: 5;
  justify-content: space-between;
}

.navbar-left {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  column-gap: 24px;
  p {
    margin: 0;
  }
}

.navbar-flag {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  a {
    opacity: 1;
  }
  a:hover {
    opacity: 1;
  }
  .other-flag {
    opacity: 0.7;
  }
  p {
    font-size: 14px;
    margin: 2px;
  }
}

.navbar-logo {
  margin-right: auto;
  .logo_mobile {
    display: none;
    svg {
      height: 40px;
      max-width: 100px;
    }
  }
  .logo_desktop {
    display: block;
    svg {
      height: 70px;
      width: 70px;
    }
  }
}

.nav-menu {
  display: flex;
  gap: 20px;
  list-style: none;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  .burger_menu_icon-active {
    display: none;
  }
  .menu-links {
    display: flex;
    gap: 20px;
    list-style: none;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    a {
      &:last-child {
        padding: 18px 24px;
        background-color: #ffd40f;
        color: #164230;
        border-radius: 50px;
      }
    }
  }
}

.nav-menu.with-flags {
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 32px;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #6d76f7;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media (max-width: 960px) {
  .navbar {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    top: 0;
  }

  .navbar-logo {
    .logo_mobile {
      display: block;
      svg {
        height: 40px;
        max-width: 100px;
      }
    }
    .logo_desktop {
      display: none;
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100vh;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    padding: 0 80px;

    .burger_menu_icon-active {
      display: block;
    }
    .menu-links {
      display: none;
    }
  }

  .nav-menu.active {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100vh;
    left: 0;
    top: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    padding: 0 80px;

    .burger_menu_icon-active {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .menu-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        &:last-child {
          padding: 18px 24px;
          background-color: #ffd40f;
          color: #164230;
          border-radius: 50px;
        }
      }
    }
  }

  .nav-menu.with-flags {
    flex-direction: column;
    justify-content: center;
  }

  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }
  .menu-icon {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

  Button {
    display: none;
  }
}
