.search_container {
  position: absolute;
  top: -150px;
  left: 0;
  display: flex;
  width: 100%;
  padding: 0 192px;
  .filters_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 34px 32px;
    border: 1px solid #e2e9e5;
    border-radius: 16px 0px 0px 16px;
    background: #ffffff;

    .icon {
      display: flex;
    }

    div {
      display: flex;
      column-gap: 16px;

      span {
        color: #4c5450;
      }

      .total_filter  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        background: #f06e36;
        border-radius: 35px;
        color: #fff;
      }
    }

    .filters {
      position: absolute;
      width: fit-content;
      flex-direction: column;
      top: 100px;
      left: 0;
      background-color: #fff;
      z-index: 10;
      filter: drop-shadow(0px 4px 20px rgba(39, 46, 42, 0.2));
      border: 1px solid #e2e9e5;
      border-radius: 16px;
      transition: all 0.5s ease;

      .filters_title {
        display: none;
      }

      .cross_icon {
        display: none;
      }

      .button_container {
        padding: 24px 40px;
        .apply_button {
          display: none;
        }
        .reset_button {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          padding: 10px 20px;
          background: #ffffff;
          border: 1px solid #e2e9e5;
          border-radius: 16px;
          font-weight: 500;
          font-size: 16px;
          color: #164230;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .filters-hide {
      position: absolute;
      top: -600px;
      left: 0;
      opacity: 0;
      transition: all 0.5s ease;
      width: fit-content;
      flex-direction: column;
      background-color: #fff;
      z-index: 5;
      filter: drop-shadow(0px 4px 20px rgba(39, 46, 42, 0.2));
      border: 1px solid #e2e9e5;
      border-radius: 16px;
      transition: all 0.5s ease;
    }

    .season_filters {
      display: flex;
      width: fit-content;
      transition: all 0.5s ease;
      gap: 0;
      border-bottom: 1px solid #e2e9e5;

      .tags_container {
        display: flex;
        flex-direction: column;
        padding: 40px;

        &:last-child {
          min-width: 270px;
        }
        h5 {
          font-family: "Bebas Neue";
          font-weight: 400;
          font-size: 24px;
          color: #164230;
          margin: 0;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          background: #ffffff;
          border: 1px solid #e2e9e5;
          border-radius: 2px;
          font-weight: 400;
          font-size: 18px;
          margin-right: 12px;
          width: 16px;
          height: 16px;
          margin: 0 12px 0 0;

          &:checked {
            accent-color: #f06e36;
          }
        }
      }
    }
  }

  .search_bar_container {
    display: flex;
    align-items: center;
    flex: 3;
    padding: 34px 32px;
    background: #ffffff;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #e2e9e5;
    border-radius: 0px 16px 16px 0px;

    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button {
      display: none;
    }

    i {
      margin-right: 16px;
    }

    input[type="search"] {
      border: none;
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      color: #164230;
      margin-left: 16px;
      &:focus {
        outline: none;
      }
      &::placeholder  {
        color: #4c5450;
      }
    }
  }
}

@media (max-width: 960px) {
  .search_container {
    position: initial;
    padding: 0 16px;
    top: -80px;

    .filters_container {
      position: initial;
      padding: 0;
      justify-content: center;
      border-radius: 0px 16px 16px 0px;
      order: 1;

      .filters {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        width: 100%;
        height: 100vh;
        border-radius: initial;
        padding: 16px;
        row-gap: 32px;

        .filters_title {
          display: block;
          font-family: "Bebas Neue";
          font-weight: 400;
          font-size: 36px;
          color: #164230;
          margin: 0;
        }

        .cross_icon {
          display: block;
          position: absolute;
          top: 15px;
          right: 15px;
        }
        .season_filters {
          padding: 0;
          height: 100%;
          flex-direction: column;
          width: 100%;
          gap: 0;
          border: none;

          .tags_container {
            padding: 20px;
          }
        }
        .button_container {
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translate(10%, -50%);
          display: flex;
          column-gap: 32px;
          padding: 0;

          .apply_button {
            display: block;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 24px;
            gap: 8px;
            background: #ffd40f;
            border-radius: 50px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .filters-hide {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: -900px;
        width: 100%;
        height: 100vh;
        border-radius: initial;
        padding: 16px;
        row-gap: 32px;
        opacity: 0;
        .filters_title {
          font-family: "Bebas Neue";
          font-weight: 400;
          font-size: 36px;
          color: #164230;
          margin: 0;
        }
        .season_filters {
          padding: 0;
          height: 100%;

          .button_container {
            position: absolute;
            bottom: 0;
            display: flex;
            column-gap: 32px;
          }
        }
      }

      div {
        width: fit-content;
        span {
          display: none;
        }
      }

      .icon {
        display: none;
      }
    }
    .search_bar_container {
      padding: 14px 32px;
      border-radius: 16px 0px 0px 16px;
    }
  }
}
