.single_article_container {
  padding: 0 192px;
}

.article_header {
  margin-top: 40px;

  .comments {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  ul {
    display: flex;
    margin: 0 0 4px 0;
    padding: 0;
    gap: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #4c5450;

    li {
      list-style: none;
      position: relative;

      &:nth-child(n + 2)::before {
        content: "";
        position: absolute;
        left: -11px;
        top: 11px;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #4c5450;
      }
    }
  }

  span {
    font-weight: 500;
    font-size: 16px;
    color: #4c5450;
    margin-bottom: 40px;
    text-decoration: underline;
    display: flex;
    align-items: center;

    svg {
      path {
        stroke: #4c5450;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    color: #164230;
    margin-bottom: 24px;
  }

  h1 {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 72px;
    margin: 0;
    color: #007044;
    margin-bottom: 32px;
  }

  .socials_container {
    display: flex;
    column-gap: 16px;
    margin-bottom: 80px;
  }

  .article_header_info {
    display: flex;
    color: #4c5450;
    column-gap: 24px;
    margin-bottom: 32px;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .article_header_social {
    display: flex;
    column-gap: 16px;
    margin-bottom: 80px;

    button {
      background-color: #fff;
      padding: 20px 24px;
      color: #f06e36;
      border: 1px solid #e2e9e5;
      border-radius: 50px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.content {
  display: flex;
  column-gap: 130px;
}

.article_content {
  color: #164230;
  line-height: 28px;
  font-weight: 400;
  font-size: 18px;
  max-width: 70%;
  overflow-wrap: break-word;
  flex: 1;

  h1,
  h2,
  h3,
  h4 {
    line-height: 120%;
  }

  h2 {
    font-weight: 300;
    font-size: 40px;
    color: #007044;
    margin-bottom: 40px;
    font-family: "Bebas Neue";
  }

  img {
    max-width: 100%;
    width: 100%;
    margin: 24px 0px 24px 0px;
  }

  /* .rte is the class for the blog article from shopify*/
  .rte {
    color: #164230;
    line-height: 28px;
    font-weight: 400;
    font-size: 18px;

    iframe {
      width: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 40px;
      color: #007044;
      margin-bottom: 40px;
      font-family: "Bebas Neue";
    }

    h3 {
      font-weight: 700;
      font-size: 32px;
      color: #f06e36;
      margin-bottom: 16px;
    }

    ul {
      li {
        position: relative;
        list-style: none;
      }

      li:before {
        position: absolute;
        content: "\2022";
        height: 10px;
        width: 10px;
        left: -20px;
        color: #f06e36;
      }
    }
  }
}

@media (max-width: 960px) {
  .single_article_container {
    padding: 0;
  }

  .article_header {
    padding: 16px;

    .comments {
      display: none;
    }

    .article_header_info {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .content {
    flex-direction: column;

    .article_content {
      margin-bottom: 100px;
      padding: 16px;
      max-width: 100%;

      h1,
      h2,
      h3,
      h4 {
        line-height: 120%;
      }
    }

    img {
      max-width: 400px;
      width: 100%;
    }

    h2 {
      line-height: initial;
    }
  }
}
