.pagination_container {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    color: #164230;
    background-color: #f6faf7;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    padding: 14px;
    gap: 10px;
  }

  ul {
    display: flex;
    gap: 6px;
    list-style: none;
    padding: 0;

    li {
      .pagination_link {
        height: 48px;
        width: 48px;
        color: #164230;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
}
@media (max-width: 960px) {
  .pagination_container {
    a {
      width: fit-content;
      justify-content: center;
      span {
        display: none;
      }
    }
  }
}
