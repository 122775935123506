@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import "./basket.scss";
@import "../components/Nav/nav.scss";
@import "./singleBlogPosts.scss";
@import "./recipes.scss";
@import "./singleRecipe.scss";
@import "./zipcodePage.scss";
@import "../components/Posts/posts.scss";
@import "../components/Pagination/paginations.scss";
@import "../components/PostsRow/postsrow.scss";
@import "../components/ArticleRecommendation/ArticleRecommendation.scss";
@import "../components/DiscoverBeneBono/DiscoverBeneBono.scss";
@import "../components/Footer/Footer.scss";
@import "../components/Recipe/recipe.scss";
@import "../components/RecipeSearchBar/recipeSearchBar.scss";
@import "../components/ShareButton/shareButton.scss";
@import "../components/ZipCode/zipCode.scss";
@import "../components/PriceSlider/priceSlider.scss";
@import "../components/BasketComposition/BasketComposition.scss";
@import "../components/SingleArticle/SingleArticle.scss";
@import "../components/PromotionBanner/promotionbanner.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  color: #1c221f;
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
