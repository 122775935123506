.footer {
  background: #ffedcf;
  padding: 120px 100px;
  color: #164230;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 30px;
  column-gap: 30px;

  h2 {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 56px;
    margin: 0;
    flex: 1;
  }

  .footer_links_page {
    list-style: none;
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-width: 330px;
    margin: 0;
    padding: 0;
  }

  .footer_payment {
    flex: 0.5;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 18px;
    }
    .payment_icons  {
      column-gap: 12px;
    }
  }

  .footer_benebono_info {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    div {
      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .footer_company_info {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    list-style: none;
    margin: 0;
    padding: 0;

    a:hover {
      cursor: pointer;
    }
  }
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  justify-content: space-around;

  .footer_socials {
    display: flex;
    column-gap: 24px;

    span {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: grey;
    }
  }

  p {
    margin-bottom: 0;
  }
}

@media (max-width: 960px) {
  .footer {
    padding: 60px 16px;
    display: flex;
    flex-direction: column;

    .footer_socials {
      column-gap: 15px;
      a {
        width: fit-content;
      }
    }
  }
}
