.recommendation_container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #d5efb9;
  padding: 88px;

  h2 {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 56px;
    color: #164230;
    margin: 0;
    margin-bottom: 40px;
    max-width: 1280px;
  }
}

.recommendation_articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;
  max-width: 1280px;

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; /* make scrollbar transparent */
  }

  img {
    width: 100%;
    min-width: 260px;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-bottom: 32px;
  }

  h4 {
    margin: 0;
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 36px;
    color: #164230;
    margin-bottom: 16px;
  }

  .article_info {
    display: flex;
    column-gap: 16px;
    font-size: 12px;
    color: #4c5450;
    margin-bottom: 16px;

    span {
      svg {
        path {
          stroke: #4c5450;
        }
      }
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #164230;
  }
}

@media (max-width: 960px) {
  .recommendation_container {
    padding: 16px 0px 0px 0px;
  }
  h2 {
    padding: 0px 16px 0px 16px;
  }
  .recommendation_articles {
    padding: 0px 16px 0px 16px;
  }
  h4 {
    margin-bottom: 16px;
  }
}
