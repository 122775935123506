.recipes_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  h1 {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 96px;
    text-align: center;
    color: #007044;
    margin: 0;
    background-color: #ffedcf;
    padding: 80px 0;
    margin-bottom: 100px;
    width: 100%;
  }
  div {
    width: 100%;
    position: relative;

    .recipes {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-template-rows: repeat(auto-fill, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 60px;
      margin-bottom: 80px;
      padding: 0 192px;
    }
  }
}

@media (max-width: 960px) {
  .recipes_container {
    h1 {
      margin: 0;
      font-size: 56px;
      text-align: center;
      padding: 60px 20px 100px 20px;
    }
    div {
      .recipes {
        padding: 60px 16px;
      }
    }
  }
}
