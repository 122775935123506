.recipe {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 33%;
  img {
    height: 416px;
    object-fit: cover;
    margin-bottom: 16px;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    ul {
      display: flex;
      margin: 0 0 4px 0;
      padding: 0;
      gap: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #4c5450;
  
      li {
        list-style: none;
        position: relative;
  
        &:nth-child(n + 2)::before {
          content: "";
          position: absolute;
          left: -11px;
          top: 11px;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          background-color: #4c5450;
        }
      }
    }

    p {
      margin: 0;
    }
  }

  h4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    font-weight: 400;
    font-size: 36px;
    color: #164230;
    font-family: "Bebas Neue";
  }

  span {
    position: absolute;
    background: #f06e36;
    border-radius: 20px;
    padding: 6px 16px;
    top: 24px;
    right: 24px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
}

@media (max-width: 960px) {
  a {
    width: 100%;
    .recipe {
      min-width: 100%;
      img {
        height: 343px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 16px;
      }
    }
  }
}
