.posts_row_container {
  margin-bottom: 80px;
  padding: 80px;

  h2 {
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 36px;
    margin: 0;
  }

  .post_row_info {
    width: 336px;
  }

  .article_info {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 12px;
    .comments {
      svg {
        path {
          stroke: #ffe0a5;
        }
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
  }

  .posts_link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    gap: 8px;
    width: 138px;
    height: 56px;
    background: #ffd40f;
    border-radius: 500px;
    color: #164230;
    font-weight: 500;
  }

  &:nth-child(5) {
    background-color: #f06e36;
    color: #ffe0a5;

    h2 {
      color: #ffd40f;
    }

    .article_info {
      span {
        &:nth-child(4) {
          svg {
            path {
              stroke: #ffe0a5;
            }
          }
        }
      }
    }
  }

  &:nth-child(9) {
    background-color: #d5efb9;
    color: #164230;

    h2 {
      color: #007044;
    }

    .article_info {
      .comments {
        display: flex;
        gap: 4px;
        align-items: center;
        svg {
          path {
            stroke: #164230;
          }
        }
      }
    }
  }

  li {
    list-style: none;
  }
}

@media (max-width: 960px) {
  .posts_row_container {
    padding: 16px;
    h2 {
      font-size: 24px;
    }
    .post_row_info {
      max-width: 220px;

      .tags {
        display: none;
      }

      p {
        display: none;
      }
    }
  }
}
