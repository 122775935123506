.discover_container {
  padding: 32px;
  background-color: #ffe0a5;
  color: #4c5450;
  max-width: 306px;
  min-width: 306px;
  height: fit-content;
  display: flex;
  flex-direction: column;

  h4 {
    color: #007044;
    font-family: "Bebas Neue";
    font-weight: 400;
    font-size: 32px;
    margin: 0;
    margin-bottom: 24px;
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #164230;
    margin: 0;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .button {
    padding: 15px 24px;
    width: fit-content;
    background: #ffd40f;
    border-radius: 50px;
    color: #164230;
    font-weight: 500;
    font-size: 16px;
    border: none;
    align-self: baseline;

    &:hover {
      cursor: pointer;
    }
  }
  span {
    height: 1px;
    background-color: #ffa001;
    margin: 40px 0;
  }

  .success_newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }

  .socials_container {
    display: flex;
    column-gap: 24px;

    a {
      width: fit-content;
    }
  }

  input[type="email"] {
    margin-bottom: 24px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e2e9e5;
    border-radius: 16px;

    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      color: #88928c;
    }
  }
}

@media (max-width: 960px) {
  .discover_container {
    width: 100%;
    min-width: 100%;
  }
}
